<template lang="pug">
	switches#tool-mode-switch(v-model="isDarkActive")
</template>

<script>
import { defaultColor } from '@/constants/config';
import Switches from 'vue-switches';

export default {
    name: 'ThemeSwitch',
    components: {
        Switches,
    },
    data: () => ({
        isDarkTheme: false,
        isDarkActive: false,
    }),

    created() {
        const color = this.getThemeColor();
        this.isDarkActive = color.indexOf('dark') > -1;
        this.isDarkTheme = color.indexOf('dark') > -1;
    },
    methods: {
        getThemeColor() {
            return localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
        },
    },
    watch: {
        isDarkActive(val) {
            let color = this.getThemeColor();
            let isChange = false;

            if (val && color.indexOf('light') > -1) {
                isChange = true;
                color = color.replace('light', 'dark');
            } else if (!val && color.indexOf('dark') > -1) {
                isChange = true;
                color = color.replace('dark', 'light');
            }

            if (isChange) {
                localStorage.setItem('themeColor', color);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        },
    },
};
</script>
